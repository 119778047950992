body {
  margin: 0;
  font-family: roboto !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.course-item-wrapper {
  width: auto;
  padding: 20px;
  margin:0 10px 10px 10px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 12px #0000001a;
  border-radius: 5px;
  opacity: 1;
  height: -moz-fit-content;
  height: auto;
}

.course-item-title {
  text-transform: capitalize;
  width: 100%;
  text-align: left;
  letter-spacing: -0.2px;
  font-family: 'Open Sans', sans-serif;
  color: #535252;
  font-weight: 700;
  opacity: 1;
  font-size: 16px;
  cursor: pointer;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  
}

.course-item-desc {
  width: 100%;
  letter-spacing: -0.2px;
  font-family: sans-serif;
  color: #000000;
  opacity: 0.62;
  font-size: 20px;
  min-height: 60px;
  max-height: 60px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: normal;
}

.course-item-starts {
  text-align: left;
  letter-spacing: -0.2px;
  color: #535252;
  opacity: 1;
  font-size: 20px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
}

.course-item-image {
  width: 100%;
  height: 197px;
  border-radius: 21px;

}

.course-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.slick-slider {
  width: 100%;
}

.btn-register {
  background-color: #02ADDA !important;
  text-align: center !important;
  letter-spacing: 0px !important;
  color: #FFFFFF !important;
  font-size: 20px !important;
  opacity: 1 !important;
  width: 90%;
  font-family: 'Open Sans', sans-serif;
  padding: 8px 6px !important;
  border-radius: 10px !important;
  border-color: #02ADDA !important;
  font-weight: 500 !important;
}


.course-feedback-item-wrapper {
  width: auto;
  padding: 20px;
  margin: 10px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 24px #0000001a;
  border-radius: 24px;
  opacity: 1;
  height: -moz-fit-content;
  height: auto;
  align-self: stretch;
}


.course-feedback-item-title {
  width: 100%;
  text-align: left;
  letter-spacing: -0.2px;
  font-family: sans-serif;
  color: #535252;
  opacity: 1;
  font-size: 20px;
  margin: 10px 0px;
  display: flex;
  align-items: center;

}

.course-feedback-item-desc {
  width: 100%;
    letter-spacing: -0.22px;
    font-family: sans-serif;
    color: #000000;
    opacity: 0.62;
    font-size: 22px;
    height: 135px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.course-feedback-item-starts {
  text-align: left;
  letter-spacing: -0.2px;
  color: #535252;
  opacity: 1;
  font-size: 20px;
  display: flex;
  align-items: center;
}

.course-feedback-item-image {
  width: 48px;
  height: 48px;
  border-radius: 50px;
  margin-right: 20px;
}

.course-feedback-item-name {
  text-align: left;
  letter-spacing: -0.2px;
  color: #02ADDA;
  opacity: 1;
  font-size: 20px;
  font-weight: 700;
  text-transform: capitalize;
}

.course-feedback-stars {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.course-educator-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 3rem;
}

.course-educator-image {
  margin-right: 25px;
}

.course-educator-image>img {
  width: 150px;
  border-radius: 50%;
  height: 150px;
}


.course-educator-name {
  text-align: left;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  letter-spacing: -0.36px;
  color: #02ADDA;
  opacity: 1;
  font-size: 36px;
  margin-bottom: 1rem;
}

.course-educator-tag {
  text-align: left;
  letter-spacing: -0.24px;
  font-family: sans-serif;
  color: #2F2C2C;
  opacity: 1;
  font-size: 24px;
  margin-bottom: 1.5rem;
}

.course-educator-domain {
  text-align: left;
  letter-spacing: 0px;
  font-family: sans-serif;
  color: #000000;
  opacity: 1;
  font-size: 16px;
}

.course-educator-desc {
  position: relative;
  margin-top: 60px !important;
  
  letter-spacing: 0px;
  /* font-family: sans-serif; */
  /* margin-top: 40px; */
  color: #2F2C2C;
  opacity: 1;
  font-size: 27px;
  font-weight: 600;
  line-height: 48px;
  min-height: 200px;
    
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}



@media only screen and (min-device-width: 360px) and (max-device-width: 667px) {

  .btn-register{
    width: 100%;
  }
  .course-item-title{
    font-size: 18px;
  }
  .course-item-desc{
    font-size: 14px;
  }
  .course-educator-desc{
    line-height: 32px;
    font-size: 14px;
    margin-top: 30px !important;
    min-height: 0px
  }

  .course-educator-wrapper{
    display: flow-root;
  }

  .course-educator-image{
    min-width: 100%
  }

  .domain {
    background: rgba(2, 173, 218, 24%);
    padding: 10px 20px;
    margin: 5px;
    border-radius: 19px;
    display: inline-table;
    font-size: 14px;
    letter-spacing: 0.5px;
  }

  .certificate-heading-row{
    left: 0 !important;
  }

  .course-educator-name{
    font-size: 18px;
  }

  .course-educator-tag{
    font-size: 14px;
    margin-bottom: 0.5rem;
  }
  .course-feedback-item-name{
    font-size: 18px;
  }

  .course-feedback-item-desc{
    font-size: 14px;
  }
  
  .course-feedback-stars{
    font-size: 14px;
  }
  .course-item-starts{
    font-size: 14px;
  }
}

.course-details-container{
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.course-title{
    font-size: 32px;
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
}

.Course-short-details{
    font-size: 16px;
}

.book-course{
    border:2px solid #02ADDA;
    border-radius: 14px;
    padding: 40px 20px;
    justify-content: center;
    box-shadow: 0px 3px 24px #00000029;
}

.book-course-btn{
    border: none !important;
    color: #fff ;
    font-size: 20px !important;
    height: 58px;
    width: 80%;
    border-radius: 14px !important;
    font-weight: 700 !important;
    background-color: #02ADDA !important;
}

.book-course-btn:hover{
    border: none !important;

}
.session-info {
    display: flex;
    margin-bottom: 1rem;
    justify-content: space-between;
}

.date-box-container {
    display: flex;
    justify-content: space-between;
    margin: 20px 0px 2rem 0;
}
.day-box {
    padding: 10px;
    border: 2px solid #9B9999;
    width: 64px;
    font-size: 28px;
    height: 64px;
    
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: #9B9999;
    
}

.active_day_box{    
    border: 4px solid #02ADDA;
    color: black;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
}

.border-right{
    border-right: 2px solid #313131;
}

.width-50{
    width:50%;
}

.original-value{
    margin-left: 1rem;
    text-decoration: line-through;
}

.discount-value{
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: #FF0000;
    padding-left: 2rem;
    text-decoration: none !important;
}
.ml-55{
    margin-left: 55px;
}


.ml-39{
    margin-left: 39px;
}
.video-image {    
    overflow: hidden;
    height: 438px;
    width: fit-content;
    display: flex;
    align-content: center;
    border-radius: 24px;
}

.image-container {    
    width: 100%;
    border-radius: 24px;
    background-size: cover;
    background-repeat: no-repeat;
}
.font-20{
    font-size: 20px;
    /* display: flex; */
}
.font-24{
    font-size: 24px;
}



.video-image-container{
    background: black;
    border-radius: 24px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.video-size{
    min-height: 100%;
    min-width: 100%;
    overflow: hidden;
}


@media only screen and (min-device-width: 360px) and (max-device-width: 1180px) {

    .book-course-btn{
        border-radius: 10px !important;
    }
    .font-20{
        font-size: 16px;
    }
    .day-box{
        border: 1px solid #9B9999;
    }
    .book-course-btn{
        height: 50px;
        width: 100%;
    }
    .active_day_box{
        border: 1px solid #02ADDA;
    }
    .book-course{
        border: 1px solid #02ADDA;
    }
    .video-image{
        border-radius: 24px;
    }
    .video-size{
        width:100%;
        height: auto;
    }
    .batch-details-heading{
        text-align: center;
    }
    .session-info {
        display: block;
    }
    .original-value{
        margin-left: 1rem;
        /* text-decoration: line-through; */
    }
    .discount-value{
        text-decoration:none !important;   
    }
    

    .course-title {
        font-size: 18px;        
    }

    .Course-short-details {
        font-size: 14px;
    }

    .video-margin-bottom{
        margin-bottom: 40px
    }

    .day-box{
        font-size: 20px;
        padding: 5px;
        width: 39px;
        height: 39px;
    }
}
/* @media only screen and (min-device-width: 667px) and (max-device-width: 1280px) {
     .discount-value{
        padding-right: 0rem;
    }
} */
.batch_list{
    margin-top: 48px;
}

.batch_item {
    display: flex;
    width: 100%;
    height: 64px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 24px #00000014;
    border-radius: 13px;
    justify-content: space-between;
    align-items: center;
    padding-left: 28px;
    padding-right: 28px;
    margin-bottom: 20px;
    border: 4px solid transparent;
    cursor: pointer;
}

.disabled_batch{
    background: #E0E0E0 0% 0% no-repeat padding-box !important;
    cursor: default !important;
    border: 4px solid #E0E0E0 !important;
}

.active_batch {
    border: 4px solid #02ADDA;
}

.batch_date {
    letter-spacing: 0px;
    color: #2F2C2C;
    font-size: 20px;
}

.batch_time {
    letter-spacing: 0px;
    font-size: 20px;
    font-weight: bold;
    color: #2F2C2C;
}


.batch_list .row .col-md-6:nth-child(even){
    margin-left: auto;
}


@media only screen and (min-device-width: 360px) and (max-device-width: 667px) {
.batch_date{
font-size: 16px;
}


.batch_time{
    font-size: 16px;
}

}
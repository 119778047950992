
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body{
  position: relative;
  color: #000000;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.sectionTitle {
  font-size: 24px;
    /* text-transform: uppercase; */
    color: #2F2C2C;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    margin-bottom: 17px;
}

.titleUnderline {
  height: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 48px;
}

.thickLine {
  width: 25%;
  background-color: #000000;
  border-radius: 8px;
  height: 8px;
}

.thinLine {
  background-color: #000000;
  height: 1px;
  width: 100%;
}


@media only screen and (min-device-width: 360px) and (max-device-width: 667px) {
  .thickLine{
    width: 65%;
  }

  .sectionTitle{
    font-size: 18px;
  }
  
  .titleUnderline{
    margin-bottom: 30px;
  }
}
.faqAccordion {
    margin-top: 36px;
}

.faqAccordion .accordion-item {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 24px #00000021;
    border-radius: 24px;
    border: 1px solid #DBDBDB;
    margin-bottom: 20px;
}

.faqAccordion .accordion-item:last-of-type, .faqAccordion .accordion-item:last-of-type .accordion-button {
    border-top-left-radius: 24px !important;
    border-top-right-radius: 24px !important;
    border-bottom-left-radius: 24px !important;
    border-bottom-right-radius: 24px !important;
}

.faqAccordion .accordion-item:first-of-type, .faqAccordion .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 24px !important;
    border-top-right-radius: 24px !important;
    border-bottom-left-radius: 24px !important;
    border-bottom-right-radius: 24px !important;
}

.faqAccordion .accordion-button:not(.collapsed) {
    background-color: #FFFFFF;
    box-shadow: none;
}

.faqAccordion .accordion-item .accordion-button {
    
    padding-top: 29px;
    padding-bottom: 29px;
    padding-left: 44px;
    padding-right: 44px;
    color: #02ADDA;
    font-size: 20px;
    font-weight: 200;
    border-top-left-radius: 24px !important;
    border-top-right-radius: 24px !important;
    border-bottom-left-radius: 24px !important;
    border-bottom-right-radius: 24px !important;
    text-transform: capitalize;
}

.faqAccordion .accordion-body {
    
    padding-left: 44px;
    padding-top: 0;
    padding-right: 75px;
    padding-bottom: 29px;
    color: #535252;
    font-size: 18px;
}

.faqAccordion .accordion-button:not(.collapsed)::after {
    background-image: url('../images/upArrow.svg');
    transform: none;
    width: 24px;
    height: 24px;
    margin-left: 10px;
}

.faqAccordion .accordion-button::after {
    background-image: url('../images/downArrow.svg');
    width: 24px;
    height: 24px;
    margin-left: 10px;
}

.faqAccordion .accordion-button:focus {
    box-shadow: none;
    border-color: #fff;
}

.faqConatainer {
    margin-top: 20px;
}


@media only screen and (min-device-width: 360px) and (max-device-width: 667px) {
.faqAccordion .accordion-item .accordion-button{
    font-size: 18px;
    padding-left: 15px;
    padding-right: 15px;
}
.faqAccordion .accordion-body{
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
}
/* .faqAccordion .accordion-item .accordion-button {
    color: #02ADDA;
} */
}

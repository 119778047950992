*{
 font-family: open-sans, sans-serif;
}
.dark {
  background: #313131;
  z-index: 1047;
}
.alert {
  margin: 0 !important;
}

.errorMessage {
  font-size: 14px;
  color: red;
}
.error {
  color: red;
}
.logo-size {
  height: 43px;
}

.modelbtn {
  background: #02adda !important;
  font-size: 20px !important;
  width: 100%;
  border: none !important;
  border-radius: 12px !important;
 
  font-weight: 700 !important;
  box-shadow: 0px 3px 24px #00000029;
  height: 50px;
  font-family: "Open Sans", sans-serif;
}

.sendotp-link {
  color: #0d7ae6;
  cursor: pointer;
  font-size: 14px;
}

.sendotp-link:hover {
  text-decoration: underline;
}

.text-right {
  text-align: right;
}

.container-margin-bottom {
  margin-bottom: 3rem;
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 90vw !important;
  }

  .custom-container {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .container-margin-bottom {
    margin-bottom: 5rem;
  }
}

@media screen and (min-width: 1400px) {
  .container {
    max-width: 90vw !important;
  }

  .custom-container {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .container-margin-bottom {
    margin-bottom: 5rem;
  }
}

@media screen and (min-width: 1600px) {
  .container {
    max-width: 90vw !important;
  }

  .custom-container {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .container-margin-bottom {
    margin-bottom: 5rem;
  }
}

@media screen and (min-width: 1900px) {
  .container {
    max-width: 90vw !important;
  }

  .custom-container {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .container-margin-bottom {
    margin-bottom: 5rem;
  }
}

.certificate-heading-title {
  color: #2f2c2c;
  font-size: 32px;
  width: auto;
}

.certificate-heading-row {
  position: relative;
  left: -105px;
  padding: 10px;
  width: 100%;
  margin-left: 10px;
}

.certificate-sub-heading {
  font-size: 22px;
  color: #2f2c2c;
  margin-top: 16px;
  line-height: 32px;
}

.certificate-heading-icon {
  position: relative;
  left: -20px;
}

.pt-0 {
  padding-top: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.UpcomingFreeWorkshops {
  margin-top: 80px;
}

.comment-txt {
  letter-spacing: -0.2px;
  color: #000000;
  opacity: 0.62;
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  margin-top: 20px;
  font-family: "Open Sans", sans-serif;
}

.loader-div {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.loader {
  width: 100px;
  height: 100px;
  z-index: 9999;
  background-image: url("../images/loader.gif");
  background-size: contain;
}

/* .quotes::before {
  position: absolute;
  content: url("../images/1_quote.svg");
  left: -80px;
} */

/* .quotes::after {
  position: absolute;
  right: -90px;
  content: url(../images/2_quote.svg);
  bottom: 0px;
} */
.quotes{
  font-weight: 300;
}
.font-20 {
  font-size: 20px;
}

.font-24 {
  font-size: 24px;
}

.font-family-Helvetica {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

.certificate-display {
  margin-top: 60px;
  display: flex;
  justify-content: center;
}

.certificate {
  width: 90%;
  border: 24px solid #98f5ff;
  border-radius: 24px;
  ;
}


.disable {
  /* pointer-events: none; */
  cursor: not-allowed !important;
  opacity: 0.65 !important;
}

@media only screen and (min-device-width: 360px) and (max-device-width: 667px) {
  .certificate-heading-title {
    font-size: 18px;
    line-height: 32px;
  }

  .certificate-sub-heading {
    font-size: 14px;
  }

  .logo-size {
    height: 24px;
  }

  .certificate {
    border: none !important;
    border-radius: 10px;
  }

  .certificate-heading-row {
    width: 80%;
  }

  .comment-txt {
    font-size: 14px;
  }
  .about-underline {
    width: 230px !important;
  }
  .course-feedback-underline {
    width: 300px !important;
  }
  
  .faq-underline {
    width: 100px !important;
  }
  
  .courses-underline {
    width: 700px !important;
  }
  
  .available-batches-underline {
    width: 380px !important;
  }
  .about-speaker-underline {
    width: 390px !important;
  }
}
.about-underline {
  width: 230px;
}
.course-feedback-underline {
  width: 300px;
}

.faq-underline {
  width: 100px;
}

.courses-underline {
  width: 430px;
}

.available-batches-underline {
  width: 310px;
}
.about-speaker-underline {
  width: 310px;
}
.curser-pointer {
  cursor: pointer;
}

.modal-body {
  padding: 0rem 2rem 2rem 2rem !important;
}

.modal-header {
  padding: 2rem !important;
}

.modal-content {
  border-radius: 12px !important;
}


.success-checkbox{
  border-radius:200px;
   height:200px;
    width:200px; 
    background: #F8FAF5; 
    margin:0 auto;
}
.success-card{
  padding: 60px 0px;
    text-align: center;
}

i {
  color: #9ABC66;
  font-size: 100px;
  line-height: 200px;
  margin-left:-15px;
}


.success-text {
  color: #404F5E;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size:20px;
  margin: 0;
  padding: 10px 20px;
}

.success-heading{
  color: #88B04B;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
  margin-top: 10px;
}


.social_footer_ul li i {
  width: 20px;
  height: 20px;
  text-align: center;
}

.alert-heading {
    color: inherit;
    display: flex;
    justify-content: center;
}
.bottombar-container {
    position: fixed;
    bottom: 0px;
    width: 100%;
    z-index: 1000;
    background-color: #313132c5;
    color: #FFFFFF;
    padding-left: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 25px;
}

.registration_closes {
    display: flex;
    align-items: center;
}

.registration_closes div:nth-child(1) {
    width: 31px;
    height: 36px;
    background: transparent url('../images/clock.svg') 0% 0% no-repeat padding-box;
    margin-right: 13px;
}

.registration_closes div:nth-child(2) {
    letter-spacing: 0px;
    color: #FFFFFF;
    font-size: 16px;
}

.registration_remaining_time {
    letter-spacing: 0px;
    color: #FFFFFF;
    font-size: 14px;
    margin-top: 8px;
    font-weight: 500;
}

.costTitle {
    letter-spacing: 0px;
    color: #FFFFFF;
    font-size: 16px;
}

.actualCost {
    display: flex;
    margin-top: 8px;
}

.actualCost div:nth-child(1) {
    letter-spacing: 0px;
    color: #fff;
    font-size: 18px;
    margin-right: 25px;
    font-weight: bold;
}

.actualCost div:nth-child(2) {
    letter-spacing: 0px;
    color: #FF0000;
    font-size: 18px;
    font-weight: bold;
}

.batch {
    display: flex;
    align-items: center;
}

.batch div:nth-child(1) {
    width: 29px;
    height: 34px;
    background: transparent url('../images/batch.svg') 0% 0% no-repeat padding-box;
    margin-right: 13px;
}

.batch div:nth-child(2) {
    letter-spacing: 0px;
    color: #FFFFFF;
    font-size: 16px;
}

.batch_start_time {
    letter-spacing: 0px;
    color: #FFFFFF;
    font-size: 18px;
    margin-top: 8px;
    font-weight: 700;
}
.batch_schedule {
    margin-right: 97px;
}
.footer-starts {
    margin-top: 300px;
}

.footer-container {
    position: absolute;
    bottom: -170px;
    width: 100%;
    height: 116px;
    background: #F5F6FF 0% 0% no-repeat padding-box;
    padding-top: 30px;
    padding-bottom: 30px;
    border-top: 1px solid #5e88fc;
}

.footer-container .row .col-md-4 {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.footer_content {
    text-align: left;
}

.display-flex {
    display: flex;
}

.book_course_btn {
    margin-left: -30px;
    margin-top: 10px;
    margin-right: 10px;
}

.book_course_btn button {
    width: 180px !important;
    height: 54px;
    background: #02ADDA 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 24px #00000029;
    border-radius: 24px;
    opacity: 1;
    letter-spacing: 0px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    border: none;
}

.disabledBookCourseBtn {
    background: #E0E0E0 0% 0% no-repeat padding-box !important;
    cursor: default !important;
    border: 1px solid #E0E0E0 !important;
}

.mobile-view {
    display: none !important;
}

.flex-direction-column{
    flex-direction: column;
}

.border-left-seperator {
    border-left: 2px solid rgba(255, 255, 255, 0.3);
    margin: 15px;
}

.border-right-seperator {
    border-right: 2px solid rgba(255, 255, 255, 0.3);
}

.sub-container-bottom{
    display: flex;
    justify-content: space-between;
}
@media only screen and (min-device-width: 1280px) and (max-device-width: 1316px) {
    .registration_remaining_time, .batch_start_time, .actualCost{
        font-size: 18px;
    }

    .book_course_btn button{
        width: 300px;
        /* padding: 10px; */
    }
    
}

@media only screen and (min-device-width: 1317px) and (max-device-width: 1395px) {
    .registration_remaining_time, .batch_start_time, .actualCost{
        font-size: 18px;
    }

    .book_course_btn button{
        width: 300px;
    }
 
}

@media only screen and (min-device-width: 360px) and (max-device-width: 667px) {
    .bottombar-container {
        margin-bottom: 0px;
        padding-top: 0px!important;
        height: 13.5%!important;
    }
    .footer-container .row .col-md-4 {
        display: flex;
        justify-content: left;
        padding-bottom: 10px;
    }
   
    .book_course_btn button{
        width: 100%!important;
        border-radius: 10px;
        margin-bottom: 0px;
    }
    
    .batch_schedule {
        margin-right: 0px;
    }
    .desktop-view {
        display: none !important;
    }

    .mobile-view {
        display: block !important;
    }

    .registration_closes div:nth-child(1) {
        display: none;
    }

    .batch div:nth-child(1) {
        display: none;
    }

    .registration_closes div:nth-child(2), .batch div:nth-child(2), .batch_start_time, .registration_remaining_time,.costTitle,.actualCost div:nth-child(1),.actualCost div:nth-child(2) {
        font-size: 16px;
    }

    .bottombar-container{
        padding: 10px 0 0 10px;
        height: 215px;
        width:100vw    
    }

    .book_course_btn button{
        height: 70px;
    }

    .book_course_btn{
        margin-top: 15px;
        margin-left: 5px;
        margin-bottom: 15px;
    }

    .registration_remaining_time{
        margin-top: 0;
        /* margin-left: 20px; */
    }
    
    .actualCost{
        margin-top: 0;
    }
    .batch_start_time{
        margin-top: 0;
    }
    .costTitle{
        /* margin-right: 37%; */
        width: 50%;
    }
    .registration_closes{
        width: 50%;
    }

    .batch{
        width: 50%;
        /* margin-right: 19.5%; */
    }

    .mt-10 {
        margin-top: 10px !important;
    }

    .batch_schedule{
        margin-top: 10px;
    }

    .footer-starts{
        margin-top: 500px;
    }

    .footer-container {
        position: absolute;
        bottom: -285px;
        width: 100%;
        height: 271px;
        background: #F5F6FF 0% 0% no-repeat padding-box;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .footer-container .row .col-md-6 {
        display: flex;
        justify-content: flex-start;
        padding-bottom: 15px;
    }

    .quotes::after,.quotes::before{
        display: none;
    }
}
.invite-btn{
    display: table;
    padding: 10px 20px;
    border: 2px solid #02ADDA;
    border-radius: 24px;
    color: #81a4ac;
    font-weight: 700;
    font-size: 28px;
    cursor: pointer;
}

.share-course-text{
    font-size: 28px;
    font-weight: 700;
    display: flex;
    align-items: center;
}

.share-course-container{
    box-shadow: 0px 3px 24px #00000015;
    border-radius: 24px;
    padding: 20px 30px;    
    align-items: center;
    margin-top: 80px;
}

.about-icon {
    padding-right: 1rem;
}

.about-label {
    font-size: 20px;
}

.about-sub-container {
    margin-bottom: 49px;
    display: flex;
    align-items: center;
}

.course-Label{
    font-weight: bold;
    font-size: 32px;
    margin-bottom: 2rem;
    font-family: 'Open Sans', sans-serif;
    display: flex;
    align-items: center;
}

.about-course-description {
    font-size: 20px;
    margin-bottom: 2rem;
    line-height: 48px;
    color: #000 !important;
}

.invite-btn-container{
    display: flex;
    justify-content: end;
}

.show-more {
    font-size: 16px;
    color: #02ADDA !important;
    margin-left: auto;
    cursor: pointer;
}


@media only screen and (min-device-width: 360px) and (max-device-width: 667px) {

    .about-label{
        font-size: 14px;
    }
    .invite-btn.mobile-view img{        
        width: 40px;
    }
    .invite-btn-container{
        justify-content: center;
        align-items: center;
    }
    .share-course-text{
        font-size: 18px;
        margin-bottom: 10px;
    }

    .invite-btn{
        font-size: 20px;
        padding: 5px 15px;
    }

    .share-course-container{
        padding: 20px 15px;
    }

    .about-sub-container{
        margin-bottom: 10px;

    }

    .course-Label{
        font-size: 18px;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    .about-course-description{
        font-size: 14px;
        line-height: 32px;
    }

    .mb-48{
        margin-bottom: 48px!important;
    }
}
